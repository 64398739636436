<template>
  <div>
    <div
      id="offcanvasChapters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasRemovePromotionCallLabel"
          class="offcanvas-title"
        >
          Remove promotion call<br><small>{{ selected.name }}</small>
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Next promotion</label>
          <select
            id=""
            v-model="item.year"
            name=""
            class="form-select"
          >
            <option value="2024">
              2024
            </option>
            <option value="2025">
              2025
            </option>
            <option value="2026">
              2026
            </option>
            <option value="2027">
              2027
            </option>
            <option value="2028">
              2028
            </option>
            <option value="2029">
              2029
            </option>
          </select>
        </div>
        <hr>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Reason</label>
          <textarea
            id=""
            v-model="item.reason"
            name=""
            cols="30"
            rows="10"
            class="form-control"
          />
          <!-- <p><small>This text will be displayed in the email sent to ICREA</small></p> -->
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Attachment</label>
          <input
            :ref="`file`"
            type="file"
            class="form-control"
          >
        </div>
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-success mb-1 d-grid w-100"
          @click="send"
        >
          Apply and save trace
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    selected: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      item: {
        year: null,
        reason: null,
        file: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/promotionCall',
      filters: 'convo/filters',
    }),
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/togglePromotionCall', false)
    },
    async send() {
      const file = this.$refs.file.files[0]
      this.item.file = file

      const formData = new FormData()
      formData.append('year', this.item.year)
      formData.append('reason', this.item.reason)
      formData.append('file', this.item.file)
      formData.append('researcher_id', this.selected.id)

      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/remove-promotion-call`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (resp.status === 200) {
        this.item = {
          year: null,
          reason: null,
          file: null,
        }
        await this.$store.dispatch('convo/promotionReport', this.filters)
        Vue.swal('Researcher removed successfully', '', 'success')
        // this.$toastr.success('', 'Promotion call removed successfully')
        this.$emit('reloadStats')
        this.closeOffcanva()
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      }
    },
  },
}
</script>
