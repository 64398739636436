<template>
  <div>
    <CompleteTable
      :sorted-field="sortedField"
      :data="applicants"
      :sort-mapping="sortMapping"
      :title="'ICREAs'"
      :total="applicantsTotal"
      :fetch-path="'convo/promotionReport'"
      :filter-path="'convo/promotionReport'"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <i data-feather="more-vertical" />
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <b-dropdown-item
                :href="$router.resolve({name: 'researcher.promotion-call.index', params: { researcher_id: field.id }, query: { actAs: $route.query.actAs }}).href"
                target="_blank"
              >
                <i
                  class="me-50"
                  data-feather="edit"
                /> Edit profile
              </b-dropdown-item>
              <b-dropdown-item @click="selected = field, $store.dispatch('modals/togglePromotionCall', true)">
                <i
                  class="me-50"
                  data-feather="x"
                />
                Remove promotion call
              </b-dropdown-item>
            </div>
          </b-dropdown>
          <a
            href=""
            class="btn btn-icon btn-light-secondary d-block d-sm-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasBottom"
          ><i
            data-feather="more-vertical"
          /></a>
        </td>
      </template>
      <template #customfilters>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Researcher</label>
          <v-select
            v-model="filters.user"
            label="name"
            :options="users"
            :get-option-key="option => option.id"
            @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
            @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'user')"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Area</label>
          <v-select
            v-model="filters.area_name"
            label="name"
            :options="areas"
            :get-option-key="option => option.id"
            @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event }, 'area_name')"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >ERC</label>
          <v-select
            v-model="filters.erc"
            label="name"
            :options="ercs"
            :get-option-key="option => option.id"
            @input="saveFilter({ customLabel: true, name: `ERC: ${$event ? $event.name : ''}` }, 'erc')"
            @search="onSearch({ name: $event }, 'erc/filter')"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Institution</label><!-- Its a text field because some evaluators might not have parametrized the institution -->
          <input
            v-model="filters.institution"
            type="text"
            class="form-control"
            name="nameseniorcall"
            @change="saveFilter({ customLabel: true, name: `Institution: ${$event.target.value}`, value: $event }, 'institution')"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Leave</label>
          <v-select
            v-model="filters.leave"
            label="label"
            :options="[
              { name: 'All', value: null},
              { name: 'Yes', value: true},
              { name: 'False', value: false},
            ]"
            :get-option-key="option => option.name"
            @input="saveFilter({ customLabel: true, name: `Leave: ${$event ? $event.name : ''}`, value: $event }, 'leave')"
          />
        </div>
        <hr>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Host name</label> <!-- Filter by role evaluator -->
          <v-select
            v-model="filters.host_name"
            label="name"
            :options="evaluatorsTotal"
            :get-option-key="option => option.id"
            @search="onSearch({ name: $event }, 'users/fetchEvaluatorsNoConvo')"
            @input="saveFilter({ customLabel: true, name: `Host name: ${$event ? $event.name : ''}`, value: $event }, 'host_name')"
          />
        </div>
        <!-- <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Host submitted</label>
          <v-select
            label="name"
            :options="[
              {name: 'All', value: null},
              {name: 'Yes', value: true},
              {name: 'False', value: false},
            ]"
            :get-option-key="option => option.id"
            @input="saveFilter($event, 'host_submitted')"
          />
        </div> -->
      </template>
    </CompleteTable>
    <RemovePromotionCall
      :selected="selected"
      @reloadStats="$emit('reloadStats')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import RemovePromotionCall from './RemovePromotionCall.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    CompleteTable,
    vSelect,
    RemovePromotionCall,
  },
  data() {
    return {
      selected: {},
      sortedField: 'Title',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Age & Birthday',
          checked: true,
          order: 3,
        },
        {
          name: 'Gender',
          checked: true,
          order: 4,
        },
        {
          name: 'E-mail',
          checked: true,
          order: 5,
        },
        {
          name: 'Erc',
          checked: true,
          order: 6,
        },
        {
          name: 'Institution',
          checked: true,
          order: 7,
        },
        {
          name: 'Incorporation',
          checked: true,
          order: 8,
        },
        {
          name: 'Year initial to evaluate',
          checked: true,
          order: 9,
        },
        {
          name: 'Year end to evaluate',
          checked: true,
          order: 10,
        },
        {
          name: 'Year to evaluate',
          checked: true,
          order: 11,
        },
        {
          name: 'Last promotion',
          checked: true,
          order: 12,
        },
        {
          name: 'Mark last promotion',
          checked: true,
          order: 13,
        },
        {
          name: 'Levels Inc.',
          checked: true,
          order: 14,
        },
        {
          name: 'Late retirement',
          checked: true,
          order: 15,
        },
        {
          name: 'Salary',
          checked: true,
          order: 16,
        },
        {
          name: 'Leave',
          checked: true,
          order: 17,
        },
        {
          name: 'Host name',
          checked: true,
          order: 18,
        },
        {
          name: 'Begin date host',
          checked: true,
          order: 19,
        },
        {
          name: 'End date host',
          checked: true,
          order: 20,
        },
        {
          name: 'Host e-mail',
          checked: true,
          order: 21,
        },
        {
          name: 'Host gender',
          checked: true,
          order: 22,
        },
        {
          name: 'Host submitted',
          checked: true,
          order: 23,
        },
      ],
      sortMapping: {
        Name: 'name',
        Area: 'areas',
        'Age & Birthday': 'birthday',
        Gender: 'genre.name',
        'E-mail': 'email',
        Erc: 'erc.code',
        Institution: 'host',
        Incorporation: 'researcher_start_date',
        'Year initial to evaluate': 'year_initial',
        'Year end to evaluate': 'year_end',
        'Year to evaluate': 'year_evaluate',
        Salary: 'salary.levels',
        Leave: 'leave',
        'Host name': 'name_host',
        'Begin date host': 'begin_date_host',
        'End date host': 'end_date_host',
        'Host e-mail': 'email_host',
        'Host gender': 'gender_host',

        'Host submitted': '',
        'Last promotion': '',
        'Mark last promotion': '',
        'Levels Inc.': '',
        'Late retirement': '',
      },
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      areas: 'areas/areas',
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
      applicants: 'convo/applicants',
      applicantsTotal: 'convo/applicantsTotal',
      users: 'users/fakeUsers',
      ercs: 'erc/items',
      evaluatorsTotal: 'users/evaluators',
    }),
  },
  async mounted() {
    await this.$store.dispatch('convo/promotionReport', this.filters)
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    await this.$store.dispatch('modals/fetchUserFields', 'promotion_report')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'promotion_report',
      })
    }
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name && s.name !== '' && s.name.length > 2) {
        await this.$store.dispatch(store, s)
      }
    },
  },
}
</script>
