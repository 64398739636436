<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ convo.title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              :href="$router.resolve({ name: 'admin.calls.promotion.index', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="chevron-left" /></a>
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-1"
              right
            >
              <template #button-content>
                <i data-feather="more-vertical" />
              </template>
              <div
                class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
              >
                <b-dropdown-item
                  :href="
                    $router.resolve({
                      name: 'admin.calls.promotion.edit',
                      params: { id: convo.id },
                      query: { actAs: $route.query.actAs }
                    }).href
                  "
                  target="_blank"
                >
                  Edit Promotion Call
                </b-dropdown-item>
                <b-dropdown-item
                  :href="
                    $router.resolve({
                      name: 'admin.calls.promotion.edit',
                      params: { id: convo.id },
                    }).href
                  "
                  target="_blank"
                >
                  View Promotion Call dates
                </b-dropdown-item>
                <b-dropdown-item
                  :href="$router.resolve({ name: 'admin.calls.promotion.peers-report', params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
                  target="_blank"
                >
                  Peers report
                </b-dropdown-item>
                <b-dropdown-item
                  :href="$router.resolve({ name: 'admin.calls.promotion.groups', params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
                  target="_blank"
                >
                  Assign PC Members
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div
              class="card card--senior-call-header"
            >
              <div class="card-body ">
                <div
                  v-if="!loading"
                  class="row"
                >
                  <div class="col d-flex align-items-center ">
                    <div class="card--senior-call-section me-5">
                      <strong>{{ stats.icreas }} </strong>ICREAs
                    </div>
                    <!--  <div class="card--senior-call-section me-5">
                                            <strong>50 <a href="" class="btn btn-icon btn-flat-secondary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEvaluators" aria-controls="offcanvasEvaluators"><i data-feather="eye"></i></a></strong>Evaluators
                                        </div> -->
                    <div class="card--senior-call-section me-5"> <!-- NOTA: se muestran los evaluators que han sido asignados como feedback editors -->
                      <strong>{{ stats.peers }} </strong>Peers
                    </div>
                    <div class="card--senior-call-section me-5">
                      <strong>{{ stats.hosts }} </strong>Hosts
                    </div>
                    <div class="card--senior-call-section me-5">
                      <strong>{{ stats.pc_members }} </strong>PC Members
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="loading custom-loading"
                >
                  <div class="effect-1 effects" />
                  <div class="effect-2 effects" />
                  <div class="effect-3 effects" />
                </div>
              </div>
            </div>

            <b-tabs
              v-if="!loadingTabs"
              lazy
            >
              <b-tab>
                <template #title>
                  Report
                </template>

                <PromotionReport
                  v-if="convo.id"
                  @reloadStats="reloadStats"
                />
              </b-tab>
              <b-tab active>
                <template #title>
                  All ICREAs
                </template>

                <PromotionIcreas @reloadStats="reloadStats" />
              </b-tab>
              <b-tab>
                <template #title>
                  PC Members
                </template>

                <PromotionPcMembers
                  v-if="convo.id"
                  @reloadStats="reloadStats"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  Acta
                </template>
                <PromotionActa />
              </b-tab>
              <b-tab>
                <template #title>
                  Pdf evaluators
                </template>

              </b-tab>
            </b-tabs>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import PromotionReport from './components/PromotionReport.vue'
import PromotionIcreas from './components/PromotionIcreas.vue'
import PromotionPcMembers from './components/PromotionPcMembers.vue'
import PromotionActa from './components/PromotionActa.vue'

export default {
  components: {
    BTab,
    BTabs,
    PromotionReport,
    PromotionIcreas,
    BDropdown,
    BDropdownItem,
    PromotionPcMembers,
    PromotionActa,
  },
  data() {
    return {
      loading: true,
      loadingTabs: true,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      stats: 'convo/promotionStats',
    }),
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.$store.dispatch('convo/fetchSpecific', this.$route.params.id)
      this.reloadStats()
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.loadingTabs = false
  },
  methods: {
    async reloadStats() {
      this.loading = true
      await this.$store.dispatch('convo/fetchPromotionStats', this.$route.params.id)
      this.loading = false
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
}
</script>
