<template>
  <div>
    <CompleteTable
      :sorted-field="sortedField"
      :data="applicants"
      :sort-mapping="sortMapping"
      :title="'ICREAs'"
      :total="applicantsTotal"
      :fetch-path="'convo/promotionIcreas'"
      :filter-path="'convo/promotionIcreas'"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <i data-feather="more-vertical" />
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <b-dropdown-item
                :href="$router.resolve({ name: 'researcher.administration.promotion-history.see-evaluation', query: { user: field.id, convo: convo.id, actAs: $route.query.actAs} }).href"
                target="_blank"
              >
                <i
                  class="me-50"
                  data-feather="eye"
                /> View form evaluation
              </b-dropdown-item>
              <b-dropdown-item @click="$store.dispatch('modals/toggleConvoViewPeers', true), traceUser = field">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View peers
              </b-dropdown-item>
              <!-- <div class="dropdown-divider" /> -->
              <!-- <b-dropdown-item
                :href="$router.resolve({name: 'researcher.dashboard', params: { id: field.id }, query: { actAs: $route.query.actAs }}).href"
                target="_blank"
              >
                <i
                  class="me-50"
                  data-feather="send"
                /> Send message to ICREA
              </b-dropdown-item>
              <b-dropdown-item
                :href="$router.resolve({name: 'researcher.dashboard', params: { id: field.id }, query: { actAs: $route.query.actAs }}).href"
                target="_blank"
              >
                <i
                  class="me-50"
                  data-feather="send"
                /> Send message to HOST
              </b-dropdown-item> -->
              <div class="dropdown-divider" />
              <b-dropdown-item @click="$store.dispatch('modals/toggleUserTraces', true), traceUser = field">
                <i
                  class="me-50"
                  data-feather="command"
                /> View traces
              </b-dropdown-item>
              <div class="dropdown-divider" />
              <b-dropdown-item
                :href="$router.resolve({ name: 'researcher.dashboard', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                target="_blank"
              >
                <i
                  class="me-50"
                  data-feather="edit-3"
                /> Edit profile
              </b-dropdown-item>
              <b-dropdown-item
                :href="$router.resolve({ name: 'researcher.administrative-data.researcher', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                target="_blank"
              >
                <i
                  class="me-50"
                  data-feather="edit-3"
                /> Administrative data
              </b-dropdown-item>
            </div>
          </b-dropdown>
          <a
            href=""
            class="btn btn-icon btn-light-secondary d-block d-sm-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasBottom"
          ><i
            data-feather="more-vertical"
          /></a>
        </td>
      </template>
      <template #customfilters>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Researcher</label>
          <v-select
            v-model="filters.user"
            label="name"
            :options="users"
            :get-option-key="option => option.id"
            @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
            @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'user')"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Area</label>
          <v-select
            v-model="filters.area_name"
            label="name"
            :options="areas"
            :get-option-key="option => option.id"
            @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event }, 'area_name')"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >ERC</label>
          <v-select
            v-model="filters.erc"
            label="name"
            :options="ercs"
            :get-option-key="option => option.id"
            @input="saveFilter({ customLabel: true, name: `ERC: ${$event ? $event.name : ''}`, value: $event }, 'erc')"
            @search="onSearch({ name: $event }, 'erc/filter')"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Institution</label><!-- Its a text field because some evaluators might not have parametrized the institution -->
          <input
            v-model="filters.institution"
            type="text"
            class="form-control"
            name="nameseniorcall"
            @change="saveFilter({ customLabel: true, name: `Institution: ${$event.target.value}`, value: $event.target.value }, 'institution')"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Leave</label>
          <v-select
            v-model="filters.leave"
            label="name"
            :options="[
              { name: 'All', value: null },
              { name: 'Yes', value: true },
              { name: 'No', value: false },
            ]"
            :get-option-key="option => option.name"
            @input="saveFilter({ customLabel: true, name: `Leave: ${$event ? $event.name : ''}`, value: $event }, 'leave')"
          />
        </div>
        <hr>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Host name</label> <!-- Filter by role evaluator -->
          <v-select
            v-model="filters.host_name"
            label="name"
            :options="evaluatorsTotal"
            :get-option-key="option => option.id"
            @search="onSearch({ name: $event }, 'users/fetchEvaluatorsNoConvo')"
            @input="saveFilter({ customLabel: true, name: `Host name: ${$event ? $event.name : ''}`, value: $event }, 'host_name')"
          />
        </div>
      </template>
    </CompleteTable>
    <ManageUserTraces :user="traceUser" />
    <ViewPeers :user="traceUser" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import ManageUserTraces from '@/views/back/partials/offcanvas/ManageUserTraces.vue'
import vSelect from 'vue-select'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import ViewPeers from './ViewPeers.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    CompleteTable,
    vSelect,
    ManageUserTraces,
    ViewPeers,
  },
  data() {
    return {
      traceUser: {},
      selected: {},
      sortedField: 'Title',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Erc',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
        {
          name: 'Previous grade',
          checked: true,
          order: 5,
        },
        {
          name: 'Previous years',
          checked: true,
          order: 6,
        },
        {
          name: 'Hosts',
          checked: true,
          order: 7,
        },
        {
          name: 'Peers',
          checked: true,
          order: 8,
        },
        {
          name: 'Promotion commitee',
          checked: true,
          order: 9,
        },
        {
          name: 'R.R',
          checked: true,
          order: 10,
        },
        {
          name: 'F.R.P',
          checked: true,
          order: 11,
        },
        // {
        //   name: 'F.E',
        //   checked: true,
        //   order: 12,
        // },
        {
          name: 'F.A',
          checked: true,
          order: 13,
        },
        {
          name: 'Submit',
          checked: true,
          order: 14,
        },
        {
          name: 'Grade',
          checked: true,
          order: 15,
        },
        {
          name: 'Future years',
          checked: true,
          order: 16,
        },
      ],
      sortMapping: {
        Name: 'name',
        Area: 'areas',
        Erc: 'erc.code',
        Institution: 'host',
        'Previous grade': 'previous_grade',
        'Previous years': 'previous_year',
        Hosts: 'hosts',
        Peers: 'peers_table',
        'Promotion commitee': 'pc_members_table',
        'R.R': 'rr',
        'F.R.P': 'frp',
        // 'F.E': 'fe',
        'F.A': 'fa',
        Submit: 'submit',
        Grade: 'grade',
        'Future years': 'future_years',
      },
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      areas: 'areas/areas',
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
      applicants: 'convo/applicants',
      applicantsTotal: 'convo/applicantsTotal',
      users: 'users/fakeUsers',
      ercs: 'erc/items',
      evaluatorsTotal: 'users/evaluators',
    }),
  },
  async mounted() {
    await this.$store.dispatch('convo/promotionIcreas', this.filters)
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    await this.$store.dispatch('modals/fetchUserFields', 'promotion_icreas')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'promotion_icreas',
      })
    }
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name && s.name !== '' && s.name.length > 2) {
        await this.$store.dispatch(store, s)
      }
    },
  },
}
</script>
