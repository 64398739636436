<template>
  <div class="card">
    <div class="card-header">
      <p>
        Having reviewed all the ICREA Research Professors under Promotion in {{ convo.year }}, the promotion committee met in Barcelona on {{ convo.meeting_day }}, reached a consensus on the marks and feedbacks as follows:
      </p>
      <br>
      <a
        class="btn btn-sm btn-flat-dark btn-primary"
        target="_blank"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Generate pdf"
        @click.stop="generatePdf(0, 'All', true)"
      >
        <i data-feather="file" /> Generate PDF with all areas
      </a>

    </div>
    <div class="card-body">
      <div
        v-for="customArea in areas"
        :key="customArea.id"
      >
        <template v-if="!customArea.parent">
          <h2
            :ref="`collapse-${customArea.id}`"
            v-b-toggle="`collapseActaArea-${customArea.id}`"
            @click="loadArea(customArea.id)"
          >{{ customArea.name }}
            <span
              class="dis-ib float-end"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Generate pdf"
            >
              <a data-action="collapse">
                <i data-feather="chevron-down" />
              </a>
              <a
                class="btn btn-sm btn-flat-dark"
                target="_blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Generate pdf"
                @click.stop="generatePdf(customArea.id, customArea.name)"
              >
                <i data-feather="file" /> Generate PDF
              </a>
            </span>
          </h2>
          <hr>
          <b-collapse :id="`collapseActaArea-${customArea.id}`">
            <div
              v-if="!loading"
              class="contain-tables"
            >
              <h3 class="mt-2">
                <span class="text-success">Researchers</span> <small style="float: right;">{{ totalResearchers }} items</small>
              </h3>
              <table class="table table-stripe table-acta">
                <thead>
                  <tr>
                    <th>Area</th>
                    <th>Name</th>
                    <th>Surname</th>
                    <th>Institution</th>
                    <th>Incorp.</th>
                    <th>Feedback to the researcher</th>
                    <th>Mark</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="researcher in promoResearchers"
                    :key="researcher.id"
                  >
                    <td>{{ customArea.code }}</td>
                    <td>{{ researcher.first_name }}</td>
                    <td><strong>{{ researcher.last_name }}</strong></td>
                    <td>{{ researcher.host ? researcher.host.code : '-' }}</td>
                    <td>{{ researcher.start_date }}</td>
                    <td v-html="researcher.feedback_text " />
                    <td v-html="researcher.mark " />
                  </tr>
                </tbody>
              </table>

              <h3 class="mt-2">
                <span class="text-dark">Promotion Commitee Members</span>
              </h3>
              <div class="row row__panel-members d-flex align-items-stretch">
                <div
                  v-for="pcm in promoPcms"
                  :key="pcm.id"
                  class="col-sm-3"
                >
                  <div class="card">
                    <div class="card-header">
                      <span class="avatar">
                        <img
                          v-if="pcm.avatarUrl"
                          :src="pcm.avatarUrl"
                          alt="Name"
                          class="img-fluid"
                          height="40"
                          width="40"
                        >
                        <img
                          v-else
                          :src="require('@/assets/images/avatars/9-small.png')"
                          alt="Name"
                          class="img-fluid"
                          height="40"
                          width="40"
                        >
                      </span>
                    </div>
                    <div class="card-body">
                      <h3>{{ pcm.name }}</h3>
                      <p class="mb-0">
                        {{ pcm.institution_name }}
                      </p>
                      <p class="mb-0">
                        {{ pcm.department_name }}
                      </p>
                      <p v-if="pcm.erc">
                        ({{ pcm.erc.code }}) {{ pcm.erc.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="loading"
              id="loading-bg"
              style="margin-left:-21px;"
            >
              <div
                class="loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </div>
          </b-collapse>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
  },
  data() {
    return {
      onlyRanking: false,
      area: null,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      areas: 'areas/areas',
      promoResearchers: 'convo/promoResearchers',
      totalResearchers: 'convo/totalRankeds',
      promoPcms: 'convo/promoPcms',
    }),
  },
  async mounted() {
    await this.$store.dispatch('areas/filter', {})
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
  methods: {
    async loadArea(id) {
      if (this.area !== id) {
        if (this.area != null) {
          this.$refs[`collapse-${this.area}`][0].click()
        }
        this.area = id
        this.loading = true

        await this.$store.dispatch('convo/fetchPromoResearchers', { area_id: id, convo_id: this.convo.id })
        this.loading = false
      } else {
        this.area = null
      }
    },
    async generatePdf(area, name, all = false) {
      Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-promotion-area-pdf/${area}?all=${all}`, {
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = `Acta ${this.convo.title} - ${name}.pdf`
        a.click()
        window.URL.revokeObjectURL(url)
        this.exporting = false
      })
    },
  },
}
</script>
